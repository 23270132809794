/* For Chrome, Safari and Opera */
::-webkit-scrollbar {
  width: 2px;
}

::-webkit-scrollbar-track {
  background: transparent; 
}

::-webkit-scrollbar-thumb {
  background: #888; 
}

::-webkit-scrollbar-thumb:hover {
  background: #555; 
}

/* For Firefox */
html {
  scrollbar-width: thin;
  scrollbar-color: #888 transparent;
}

/* For IE and Edge */
body {
  -ms-overflow-style: none;
}